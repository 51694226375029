<template>
  <div class="filter-select">
    <label :for="inputLabel">
      {{ label }}
      <span v-if="required" class="required"> *</span>
    </label>
    <div class="filter-select__select-wrapper">
      <div
        :class="['filter-select__select', errorClass]"
        role="combobox"
        v-click-outside="handleClickOutside"
      >
        <input
          ref="selectInput"
          class="filter-select__searchbar"
          type="text"
          v-model="searchbarValue"
          @focus="onInputFocus"
          @blur="handleInputBlur"
        />
        <ul
          class="filter-select__options"
          ref="selectList"
          role="listbox"
          v-if="isOpen"
        >
          <li
            :tabindex="isOpen ? 0 : undefined"
            class="filter-select__option"
            v-for="item in filteredBySearchbar"
            :key="item.id"
            @click="handleOptionClick(item.id)"
          >
            <span class="filter-select__option-text">{{
              item[$i18n.locale as keyof Option]
            }}</span>
          </li>
        </ul>
      </div>
    </div>
    <span v-if="showErrorMessage" class="error">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import type { Validation } from '@vuelidate/core'

const isOpen = ref(false)
const searchbarValue = ref('')
const selectList = ref<HTMLElement | null>(null)
const selectInput = ref<HTMLInputElement | null>(null)

interface Props {
  modelValue: string | number
  label?: string
  required?: boolean
  options: any[]
  placeholder?: string
  v?: Validation['ExtractRulesResults']
  name?: string
  sortAlphabetically?: boolean
}

interface Option {
  de: string
  en: string
  id: string
  pl: string
}

const { locale } = useI18n()

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:modelValue', modelValue: string | number): void
}>()

const { handleInput, errorMessage, errorClass, showErrorMessage, inputLabel } =
  useInput(props, emit)

const getLocalisedOption = (id: string) => {
  return props.options.find((item) => item.id === id)?.[
    locale.value as keyof Option
  ]
}

const handleOptionClick = (id: string) => {
  searchbarValue.value = getLocalisedOption(id) || ''
  handleInput.value = id
  isOpen.value = false
}

const onInputFocus = () => {
  if (searchbarValue.value !== '') {
    searchbarValue.value = ''
  }

  isOpen.value = true
}

const handleKeyPress = (event: KeyboardEvent) => {
  if (!isOpen.value) {
    return
  }

  const focusedOption = selectList.value?.querySelector(':focus')

  switch (event.key) {
    case 'Tab':
      isOpen.value = false
      props.v?.$touch()
      break
    case 'ArrowDown':
      if (focusedOption) {
        const nextOption = focusedOption.nextElementSibling as HTMLElement
        if (nextOption) {
          nextOption.focus()
        }
      } else {
        ;(selectList.value?.firstElementChild as HTMLElement).focus()
      }
      break
    case 'ArrowUp':
      //if first option is focused, focus the input
      if (!focusedOption) {
        break
      }

      if (focusedOption === selectList.value?.firstElementChild) {
        selectInput.value?.focus()
        break
      } else {
        const prevOption = focusedOption?.previousElementSibling as HTMLElement
        if (prevOption) {
          prevOption.focus()
        }
        break
      }
    case 'Enter':
      if (focusedOption) {
        ;(focusedOption as HTMLElement).click()
      }
      break
  }
}

const handleClickOutside = () => {
  searchbarValue.value = getLocalisedOption(handleInput.value) || ''
  isOpen.value = false
}

const handleInputBlur = () => {
  setTimeout(() => {
    if (!isOpen.value) {
      props.v?.$touch()
      searchbarValue.value = getLocalisedOption(handleInput.value) || ''
    }
  }, 50)
}

const filteredByLanguage = computed(() => {
  if (!props.options) {
    return []
  }

  let filtered = props.options.filter(
    (item) => item[locale.value as keyof Option],
  )

  if (props.sortAlphabetically) {
    filtered = filtered
      .sort((a, b) =>
        a[locale.value as keyof Option].localeCompare(
          b[locale.value as keyof Option],
        ),
      )
      // Move United States to the top
      .sort((a, b) => {
        if (a.id === 'United States') {
          return -1
        }
        if (b.id === 'United States') {
          return 1
        }
        return 0
      })
  }

  return filtered
})

const filteredBySearchbar = computed(() => {
  return filteredByLanguage.value.filter((item) => {
    return item[locale.value as keyof Option]
      .toLowerCase()
      .includes(searchbarValue.value.toLowerCase())
  })
})

onMounted(() => {
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyPress)
})
</script>

<style lang="scss" scoped>
.filter-select {
  margin-bottom: 24px;

  &__select-wrapper {
    position: relative;
    height: 52px;
  }

  &__select {
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    appearance: none;
    color: var(--color-text);
    border: 1px solid var(--paper-400);
    border-radius: var(--border-radius-small);

    &.input--error {
      border: 2px solid var(--red);
    }
  }

  &__searchbar {
    display: block;
    padding: 12px 16px !important;
    padding: 0;
    width: 100%;
    border: none;
    cursor: pointer;
    font-size: var(--font-size-paragraph-s);

    &:focus {
      border: none !important;
      outline: none !important;
    }
  }

  &__options {
    padding: 0;
    max-height: 50vh;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      border: none;
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      border: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--paper-300);
      border-radius: 10px;
    }
  }

  &__option {
    padding: 12px 0;
    font-size: var(--font-size-paragraph-s);
    user-select: none;
    cursor: pointer;

    &:hover {
      background-color: var(--paper-100);
    }
  }

  &__option-text {
    padding: 0 12px;
  }

  &__debug {
    color: var(--green-600);
  }
}
</style>
